<template>
    <div id="action-header" class="clipped" >
        <h1>Take Action Today</h1>
        <p>
            We are stronger together than we are alone. Whatever taking action looks like to you - 
            from sending a letter to building creative alternatives or aligning your actions with deep inner wisdom  - 
            they are all crucial in making the more just and compassionate worlds we need. We invite you to begin that journey today. 
        </p>
    </div>
</template>

<script>
    export default {
        name: "ActionHeader"
    }
</script>

<style lang="scss" scoped>

    .clipped {
        clip-path: polygon(100% 0%, 100% 70%, 50% 90%, 50% 90%, 0 70%, 0 0);
    }

    #action-header {
        display: grid;
        justify-items: center;
        align-items: center;
        background-color: #000000;
        position: relative;
        text-align: center;
        min-height: 40vh;
        padding: 0 10%;
        padding-bottom: 100px;
        
        h1 {
            position: relative;
            font-size: 2em;
            color: white;
            font-family: "Helvetica";
            margin: 3vh 0;
            background: linear-gradient(#5565d9 0 0) 50% 100% /40% 2px;
            background-repeat:no-repeat;
            padding-bottom:6px;
        }

        p {
            color: white;
            position: relative;
            font-size: 0.9em;
        }
    }

    #action-header::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('~@/assets/government-active-banner.jpg');
        background-position: center;
        background-size: cover;
        opacity: 0.5;
    }

@media screen and (min-width: 600px) {
    #action-header {

        p {
            font-size: 1em;
            padding: 0 10%;
        }
    }
}

@media screen and (min-width: 1000px) {
    #action-header {
        h1 {
            font-size: 3em;
        }

        p {
            font-size: 1.1em;
        }
    }
}
</style>