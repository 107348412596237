<template>
    <div id="create-new-action" @click="hideMsg">
        <h1>Create a new action</h1>
        <v-form id="create-campaign-form" v-model="formValid">
            <v-text-field
                v-model="title"
                :rules="[titleRules.required, titleRules.min, titleRules.max]"
                color="primary"
                required
                >
                <template v-slot:label>
                    Title<span class="red--text"> *</span>
                </template>
            </v-text-field>
            <v-text-field
                v-model="imageUrl"
                :rules="[imageUrlRules.max]"
                label="Image Url"
                color="primary"
            ></v-text-field>
            <v-textarea
                v-model="subtitle"
                :rules="[subtitleRules.max]"
                label="Subtitle"
                color="primary"
                auto-grow
            ></v-textarea>
            <v-textarea
                v-model="content"
                :rules="[subtitleRules.max]"
                label="Content"
                color="primary"
                auto-grow
            ></v-textarea>
            <div id="error-message">
                <h3>{{errorMsg}}</h3>
            </div>
            <div id="form-btns">
                <v-btn id="create-btn"
                    :disabled="!formValid"
                    color="success" 
                    @click="createAction()">
                        Create Action
                </v-btn>
                <v-btn
                    id="overlay-close-btn"
                    class="white--text"
                    color="primary"
                    @click="changeOverlay()"
                >
                    Close
                </v-btn>
            </div>
        </v-form>
        <MarkdownHtmlExamples />
    </div>
</template>

<script>
import axios from 'axios'
import cookies from 'vue-cookies'
import router from '../router'
import MarkdownHtmlExamples from '../components/MarkdownHtmlExamples.vue'

    export default {
        name: "CreateNewAction",
        components: {
            MarkdownHtmlExamples
        },
        data() {
            return {
                formValid: false,
                errorMsg: "",
                title: '',
                titleRules: {
                    required: v => !!v || 'Title is required',
                    min: v => v.length >= 1 || 'Min 1 character',
                    max: v => v.length <= 300 || 'Max 300 characters',
                },
                subtitle: '',
                subtitleRules: {
                    max: v => v.length <= 65000 || 'Max 65,000 characters',
                },
                imageUrl: '',
                imageUrlRules: {
                    max: v => v.length <= 500 || 'Max 500 characters',
                },
                content: '',
            }
        },
        methods: {
            changeOverlay() {
                this.$emit("changeOverlay")
            },
            hideMsg() {
                if(document.getElementById('error-message') != null) {
                    document.getElementById('error-message').style.display = "none";
                }
            },
            createAction() {
                let token = cookies.get('_casLt')
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/action',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        "loginToken": token,
                        "title": this.title,
                        "subtitle": this.subtitle,
                        "content": this.content,
                        "imageUrl": this.imageUrl,
                    }
                }).then(() => {
                    router.go();
                }).catch((error) => {
                    this.errorMsg = error.response.data;
                    document.getElementById('error-message').style.display = "block";
                })
            },
        }
    }
</script>

<style lang="scss" scoped>

    #create-new-action {
        width: 100%;
        margin-top: 30px;
        display: grid;
        justify-items: center;
        align-items: center;
        
        h1 {
            text-align: center;
            font-family: "Helvetica";
        }

        #create-campaign-form {
            width: 90vw;
            font-family: "Helvetica";

            #error-message {
                display: none;
                color: rgb(212, 0, 0);
                margin: 3vh 0;
            }

            #form-btns {
                display: flex;
                justify-content: space-between;
            }
        }

        #markdown-code {
            width: 90vw;
            margin-top: 5vh;
            border: 3px solid white;
            border-radius: 30px;
            color: white;
            
            h1 {
                margin: 0;
                justify-self: center;
                text-decoration: underline;
            }

            h3 {
                margin-top: 3vh;
                margin-left: 1vw;
            }

            #markdown-list-item > * {
                color: white;
            }

            #markdown-list-item > #list-item-div > h4 {
                margin-top: 3vh;
                color: #e89f4c;
            }
        }
    }

@media screen and (min-width: 1000px) {
    #create-new-action {
        
        h1 {
            justify-self: start;
            margin-left: 15vw;
        }

        #create-campaign-form {
            width: 60vw;
        }

        #markdown-code {
            width: 60vw;
        }
    }
}
</style>