<template>
    <div id="actions-container">
        <h2>Things you can do right now</h2>
        <p>
            We act to mitigate real suffering in the here and now. We take responsibility for constructing the world that we want to see: 
            a world where there is more compassion and justice for animals. 
        </p>
        <div id="tile-box">
            <div id="card-container">
                <div id="action-card-display" v-for="campaign in campaigns" :key="campaign.id">
                    <ActionCard :campaign="campaign"/>
                </div>
            </div>
        </div>
        <div id="quote-link-container">
            <p>
                “An organization which claims to be working for the needs of a community…must work to provide that community with a position of 
                strength from which to make its voice heard”
            </p>
            <p id="quoted-author">
                - Kwame Ture (formerly known as Stokely Carmichael).
            </p>
            <p id="work-with-us-link">
                <a class="anchor-color" href="/explore#contact-us-container">Work with us</a> to have your efforts listed here! 
            </p>
        </div>  
    </div>
</template>

<script>
import axios from 'axios'
import ActionCard from '../components/ActionCard.vue'

    export default {
        name: "ActionsContainer",
        components: {
            ActionCard,
        },
        beforeMount() {
            this.getCampaigns();
        },
        data() {
            return {
                campaigns: {},
            }
        },
        methods: {
            getCampaigns() {
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/action',
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.campaigns = response.data.reverse(); //reverse so newest campaings show first
                    for (let i=0; i < this.campaigns.length; i++) {
                        delete this.campaigns[i].content;
                    }
                }).catch(() => {
                    //do nothing
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .anchor-color {
        color:#d57f1e;
    }

    #actions-container {
        width: 100%;
        text-align: center;
        background: rgb(232,159,76);
        background: linear-gradient(180deg, rgba(232, 159, 76, 0) 0%, rgba(232, 159, 76, 0.2) 50%, rgba(213, 128, 30,0.4) 100%);

        h2 {
            padding: 15px 15px;
            color: #d57f1e;
            font-family: "Helvetica";
            font-size: 2em;
            margin-bottom: 2vh;
            background: linear-gradient(#5565d9 0 0) 50% 100% /20% 2px;
            background-repeat:no-repeat;
            padding-bottom:6px;
        }

        p {
            padding: 0 20px;
            font-family:  'Roboto', sans-serif;
        }

        #tile-box {
            padding-top: 5vh;

            h1 {
                padding: 15px 15px;
                color: #d57f1e;
                font-family: "Helvetica";
                font-size: 3em;
                margin-bottom: 2vh;
                background: linear-gradient(#5565d9 0 0) 50% 100% /20% 2px;
                background-repeat:no-repeat;
                padding-bottom:6px;
            }

            #card-container {
                display: grid;
                justify-items: center;

                #action-card-display {
                    width: 100vw;
                    padding: 2vh 0 2vh 0;
                }
            }
        }

        #quote-link-container {
            margin-top: 60px;
            padding-bottom: 10px;
            
            p {
                font-family:  'Roboto', sans-serif;
            }

            #work-with-us-link {
                margin-top: 50px;
            }
        }
    }

@media screen and (min-width: 600px) {
    #actions-container {
        display: grid;

        h2 {
            background: linear-gradient(#5565d9 0 0) 50% 100% /10% 2px;
            background-repeat:no-repeat;
            padding-bottom:6px;
        }

        p {
            padding: 0 70px;
        }

        #tile-box {
            #card-container {
                grid-template-columns: 50% 50%;

                #action-card-display {
                    width: 40vw;
                    max-width: 530px;
                    padding: 2vh 0 2vh 0;
                }
            }
        }

        #quote-link-container {
            display: grid;

            p {
                font-size: 1.1em;
            }

            #work-with-us-link {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    #actions-container {

        h2 {
            background: linear-gradient(#5565d9 0 0) 50% 100% /5% 2px;
            background-repeat:no-repeat;
            padding-bottom:6px;
        }

        p {
            padding: 0 100px;
            font-size: 1.4em;
        }

        #quote-link-container {
            padding-left: 10%;
            padding-right: 10%;

            p {
                font-size: 1.2em;
            }
        }
    }
}
</style>