<template>
    <div id="action-page">
        <ActionHeader />
        <div id="create-action" v-if="ulog">
            <v-btn
                color="success"
                @click="overlay = !overlay">
                Create a new campaign/action!
            </v-btn>
        </div>
        <v-overlay
            id="create-overlay"
            v-if="ulog"
            :z-index="zIndex"
            :value="overlay"
            :opacity="opacity"
            :absolute="absolute"
            >
            <div id="action-form">
                <CreateNewAction @changeOverlay="overlay = !overlay"/>
            </div>
        </v-overlay>
            <ActionsContainer />
    </div>
</template>

<script>
import ActionHeader from '../components/ActionHeader.vue'
import ActionsContainer from '../components/ActionsContainer.vue'
import CreateNewAction from '../components/CreateNewAction.vue'
import cookies from 'vue-cookies'

    export default {
        name: "Action",
        components: {
            ActionHeader,
            ActionsContainer,
            CreateNewAction
        },
        computed: {
            sessionCookie() {
                return cookies.get('_casLt') 
            },
        },
        mounted() {
            if (this.sessionCookie != undefined){
                this.ulog = true
            }
        },
        data() {
            return {
                ulog: false,
                pageOverlayFlow: false,
                overlay: false,
                zIndex: 999,
                opacity: 1,
                absolute: true,
            }
        },
    }
</script>

<style lang="scss" scoped>

    #action-page {
        width: 100vw;
        background-color: white;
        min-height: calc(100vh - 140px);
        margin-top: 70px; //account for fixed nav-bar height
        margin-bottom: 70px; //account for absolute footer height
        box-shadow: 0 5px 15px  rgb(131, 131, 131);

        #create-action {
            width: 100%;
            display: grid;
            justify-items: center;
        }

        #create-overlay {
            display: grid;
            min-height: 100vh;
            height: fit-content;

            #action-form {
                width: 90vw;
                margin-bottom: 4vh;
            }
        }
    }

@media screen and (min-width: 600px) {
    #action-page {
        margin-bottom: 150px; //account for absolute footer height
    }
}

@media screen and (min-width: 1000px) {
    #action-page {
        max-width: 1350px;
        min-height: calc(100vh - 230px);
        margin-top: 80px; //account for fixed nav-bar height
        display: grid;
    }
}
</style>